import { Resource } from './resource'

class Page extends Resource {
  static entity = 'Page'
  static baseEntity = 'Resource'
}

Resource.addModel(Page)

export { Page }
export default Page
