import { Page } from '@/models'

export default {
  data: () => ({
    defaultHome: '/'
  }),
  computed: {
    homeNode () {
      return Page.query().where('url', this.home || this.defaultHome).first()
    },
    navigation () {
      const node = this.homeNode
      if (node && node.children_ids && node.children_ids.length) {
        return Page.query().where('id', node.id).with('children', (query) => {
          query.where('resource_class_name', 'Page')
        }).first().children
      }
      return []
    }
  }
}
