export default {
  props: {
    resource: {
      type: [Object, null],
      default: null
    }
  },
  computed: {
    linkPath () { // Todo: does toooo many things?
      const link = this.resource.columns.link
      if (this.resource.resolveById(link)) { return this.resource.resolveById(link).url }
      if (this.resource && this.resource.url) { return this.resource.url }
      return '/'
    }
  },
}
