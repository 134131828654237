import Router from 'vue-router'
import { defaultRouterOptions } from '@i22/frontend-cms-utils/router'

import Default from '~/pages/default'


export function createRouter () {
  return new Router({
    ...defaultRouterOptions,
    routes: [
      {
        path: '*',
        component: Default
      }
    ],
    scrollBehavior (to, from, savedPosition) {
      return new Promise((resolve, reject) => {
        this.app.$root.$once('scrollAfterEnter', () => {
          resolve({ x: 0, y: savedPosition ? savedPosition.y : 0 })
        })
      })
    }
  })
}
