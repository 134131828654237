export default {
  props: {
    noSpacing: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    spacingClass () {
      if (this.noSpacing) { return }
      if (this.resource.columns.spacing) {
        return `spacing--${this.resource.columns.spacing.toLowerCase()}`
      } return ''
    }
  }
}
