import { Resource } from './resource'

class Footer extends Resource {
  static entity = 'Footer'
  static baseEntity = 'Resource'

  categories (type) {
    const lang = this.currentLanguage
    return Resource.query()
      .where(r => !!r.url)
      .where('path', path => path.startsWith(`/${lang}`))
      .where(r => r.columns.navigation === type)
      .get()
  }

  get mainCategories () {
    return this.categories('main')
  }

  get secondaryCategories () {
    return this.categories('secondary')
  }
}

Resource.addModel(Footer)

export { Footer }
export default Footer
