import Vue from 'vue'
import VueMatomo from 'vue-matomo'

export default ({ app, route }, inject) => {
  const cms = route.query.cms && JSON.parse(route.query.cms)

  const siteId = Number(process.env.MATOMO_SITE_MAPPING)

  if (!cms && process.env.MATOMO_URL && siteId) {
    Vue.use(VueMatomo, {
      router: app.router,

      // Configure your matomo server and site
      host: process.env.MATOMO_URL,
      siteId,

      /** Other configuration options **/
      enableLinkTracking: true,
      enableHeartBeatTimer: true,
    })
    inject('matomoConfig', {
      siteId
    })
  } else {
    inject('matomoConfig', false)
  }
}
