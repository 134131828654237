import { cmsStore } from './store/cms'

export default {
  editorModes: {
    default: ['bold', 'italic', 'link', 'unlink', 'pageBrowser']
  },
  store: {
    modules: {
      cms: cmsStore,
    }
  }
}
