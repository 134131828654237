import BaseResource from '@i22/frontend-cms-utils/models/resource'

export class Resource extends BaseResource {
  static Models = { Resource }

  static addModel (model) {
    Resource.Models[model.entity] = model
  }

  static types () {
    return Resource.Models
  }

  static getTypeModel (name) {
    return Resource.types()[name] || Resource
  }

  static getPages () {
    return this.query().where(r => !!r.url).get()
  }

  get currentLanguage () {
    if (this.resource_class_name !== 'Language') {
      return this.parentResource.currentLanguage
    }
    return this
  }

  get currentPage () {
    if (this.resource_class_name !== 'Page') {
      return this.parentResource.currentPage
    }
    return this
  }

  languagePath (lang) {
    return Resource.query()
      .where(r => r.columns.language === lang)
      .where('resource_class_name', 'Language')
      .first().path
  }

  getResourceByUrl (url) {
    return Resource.query()
      .where(r => r.url === url)
      .first()
  }

  get childrenPages () {
    return Resource.query()
      .where(r => !!r.url)
      .where('parent_id', this.id)
      .get()
  }

  get homeSite () {
    return Resource.query()
      .where('parent_id', this.currentLanguage.id)
      .where(r => !!r.url)
      .first()
  }

  getPageByPath (path) {
    return Resource.query()
      .where(r => !!r.url)
      .where('path', path)
      .first()
  }

  resolveById (id) {
    return this.findBy('id', id).first()
  }

  resolveByIds (ids) {
    return this.findBy('id', ids).get()
  }

  static findBy (key, values) {
    return Resource.query()
      .where(key, values)
      .with('parent')
      .with('children')
      .orderBy('sort_key')
  }

  findBy (key, values) {
    return Resource.findBy(key, values)
  }

  resolveChildren () {
    return this.resolveByIds(this.children_ids)
  }

  categories (type) {
    const lang = this.currentLanguage.columns.language
    return Resource.query()
      .where(r => !!r.url)
      .where('path', path => path.startsWith(`/${lang}`))
      .where(r => r.columns.navigation === type)
      .orderBy('sort_key')
      .get()
  }

  get mainCategories () {
    return this.categories('main')
  }

  get secondaryCategories () {
    return this.categories('secondary')
  }
}

export default Resource
