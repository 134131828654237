export const types = {
  SHOW_CHANGES: 'SHOW_CHANGES',
  HIDE_CHANGES: 'HIDE_CHANGES'
}

export const cmsStore = {
  namespaced: true,
  state: () => ({
    changesVisible: false
  }),
  actions: {
    showChanges ({ commit }) {
      commit(types.SHOW_CHANGES)
    },
    hideChanges ({ commit }) {
      commit(types.HIDE_CHANGES)
    }
  },
  mutations: {
    [types.SHOW_CHANGES] (state) {
      state.changesVisible = true
    },
    [types.HIDE_CHANGES] (state) {
      state.changesVisible = false
    }
  }
}
