
export default {
  mounted () {
    if (this.$matomoConfig) {
      window._paq.push(['setReferrerUrl', window.location.href]);
      window._paq.push(['setCustomUrl', window.location.pathname]);
      window._paq.push(['setDocumentTitle', this.pageTitle]);

      // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
      window._paq.push(['trackPageView']);

      // make Matomo aware of newly added content
      const content = document.getElementById('__nuxt');
      window._paq.push(['MediaAnalytics::scanForMedia', content]);
      window._paq.push(['FormAnalytics::scanForForms', content]);
      window._paq.push(['trackContentImpressionsWithinNode', content]);
    }
  },
  computed: {
    pageTitle () {
      if (this.resource) {
        return this.resource.columns.meta_title || this.resource.columns.meta_og_title || this.resource.columns.title
      }
    },
  },
}
